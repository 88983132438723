import cookie from 'js-cookie'
import { detectBreakpoint, useViewport } from '~viewport'

/**
 * @type {import('@nuxt/types').Plugin}
 */
export default async function ({ nuxtState }, inject) {
  const options = {"breakpoints":{"xs":330,"sm":680,"md":960,"lg":1100,"xl":1280,"2xl":1536},"cookieName":"viewport","defaultBreakpoints":{"desktop":"lg","mobile":"xs"},"fallbackBreakpoint":"lg"}
  let breakpoint

  if (nuxtState.viewport) {
    breakpoint = nuxtState.viewport
  } else {
    const viewportCookie = cookie.get(options.cookieName)
    breakpoint = await detectBreakpoint.call(options, viewportCookie, navigator.userAgent)
  }

  const viewport = useViewport(options, breakpoint)
  inject('viewport', viewport)
}
