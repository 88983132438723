const middleware = {}

middleware['authenticated'] = require('../middleware/authenticated.ts')
middleware['authenticated'] = middleware['authenticated'].default || middleware['authenticated']

middleware['legacyDealerSlug'] = require('../middleware/legacyDealerSlug.ts')
middleware['legacyDealerSlug'] = middleware['legacyDealerSlug'].default || middleware['legacyDealerSlug']

middleware['load-ratings'] = require('../middleware/load-ratings.ts')
middleware['load-ratings'] = middleware['load-ratings'].default || middleware['load-ratings']

middleware['redirects'] = require('../middleware/redirects.ts')
middleware['redirects'] = middleware['redirects'].default || middleware['redirects']

middleware['redirectToLowerCase'] = require('../middleware/redirectToLowerCase.ts')
middleware['redirectToLowerCase'] = middleware['redirectToLowerCase'].default || middleware['redirectToLowerCase']

export default middleware
